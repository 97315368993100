import React from "react";
import styled from "styled-components";
import PageMeta from "../components/PageMeta";
import ContactPage from "../components/ContactPage";

export default function AccreditationPage() {
  return (
    <>
      <PageMeta title="QCL - Careers" path="careers" />
      <div className="col3 t-col2">
        <h1 className="top">Careers</h1>
        <p className="lead">Enhance your career with a position at QCL.</p>
        <p>
          QCL has grown to become a very experienced local domiciliary care
          company with a gold standard status from the London Borough of
          Hounslow. Our services are always in demand and we currently require
          dedicated new care staff to join our friendly and professional team.
          We offer permanent and regular employment with guaranteed hours,
          regular PAYE earnings, a variety of shifts every day of the week, and
          annual paid holidays. The pay is always above the inner London working
          wage, currently £13.15 per hour and successful carers regularly earn
          above this.
        </p>
        <p>
          If you would like to join ths successful and professional company,
          send your CV and contact details to: info@qcl-uk.com
        </p>
      </div>
      <div className="col3 t-col1">
        <ContactPage />
      </div>
    </>
  );
}
