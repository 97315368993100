import React from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";

const StyledContactForm = styled(ContactForm)`
  background-color: white;
  border-radius: 0.4rem;
  padding: 2rem;
`;

export default function ContactPage({ className }) {
  return <StyledContactForm className={className} />;
}
